import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 0px;\n  width: ",
        ";\n  border-top-left-radius: ",
        " !important;\n  border-bottom-left-radius: ",
        " !important;\n  border-top-right-radius: ",
        " !important;\n  border-bottom-right-radius: ",
        " !important; \n  border: 1px solid ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import Button from "./Button";
var IconButton = styled(Button).withConfig({
    componentId: "sc-86670454-0"
})(_templateObject(), function(param) {
    var scale = param.scale;
    return scale === "sm" ? "32px" : "48px";
}, function(param) {
    var side = param.side;
    return side === "center" || side === "right" ? "0" : "5px";
}, function(param) {
    var side = param.side;
    return side === "center" || side === "right" ? "0" : "5px";
}, function(param) {
    var side = param.side;
    return side === "center" || side === "left" ? "0" : "5px";
}, function(param) {
    var side = param.side;
    return side === "center" || side === "left" ? "0" : "5px";
}, function(param) {
    var theme = param.theme, isActive = param.isActive, showBorder = param.showBorder;
    return showBorder ? isActive ? theme.colors.alienPurple : theme.colors.inputBorder : "0px";
});
export default IconButton;
