import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import {
  Box,
  Flex,
  LogoutIcon,
  ActualLogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  CakePrice,
  MetamaskIcon,
} from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { usePendingTransactions } from 'state/transactions/hooks'
import { useAccount } from 'wagmi'
import WalletModal, { WalletView } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'
import styled, { useTheme } from 'styled-components'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { useWindowDimensions } from 'state/wallet/hooks'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { LabelText } from '@pancakeswap/uikit/src/widgets/Menu/components/UserMenu'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const StyledFlex = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 20px;
  gap: 0px;
  // border-bottom: 1px solid ${({ theme }) => theme.colors.inputBorder};
  a {
    gap: 5px;
    width: 100%;
    display: flex;
    align-items: center;
  }
`

const UserMenu = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { chainId, isWrongNetwork } = useActiveChainId()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const accountEllipsis = account ? `${account.substring(0, 2)}...${account.substring(account.length - 4)}` : null

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }
  const [hoverWallet, setHoverWallet] = useState(false)
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const cakePrice = useCakeBusdPrice()

  const {connector} = useActiveWeb3React()

  const UserMenuItems = () => {
    if (!account || width <= 1200) {
      return (
        <>
          <StyledFlex>
            {/* <CakePrice cakePriceUsd={parseFloat(cakePrice?.toFixed(4))} /> */}
            <NetworkSwitcher />
            {!account && (
              <ConnectWalletButton scale="sm" style={{ height: 'auto', fontSize: '14px' }}>
                <Box style={{ fontFamily: 'Syncopate, sans-serif', letterSpacing: '-0.03em' }}>
                  <Trans>Connect Wallet</Trans>
                </Box>
              </ConnectWalletButton>
            )}
          </StyledFlex>
          {account && (
            <>
              <UserMenuDivider />
              <UserMenuItem style={{ justifyContent: 'flex-start' }}>
                <MetamaskIcon />
                <LabelText title={accountEllipsis || ''}>{accountEllipsis || ''}</LabelText>
              </UserMenuItem>
              <WalletUserMenuItem isWrongNetwork={chainId !== ChainId.BSC} onPresentWalletModal={onClickWalletMenu} />
              <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
                {t('Recent Transactions')}
                {hasPendingTransactions && <RefreshIcon spin />}
              </UserMenuItem>
              <UserMenuDivider />
              <UserMenuItem as="button" onClick={logout}>
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  {t('Disconnect')}
                  <ActualLogoutIcon />
                </Flex>
              </UserMenuItem>
            </>
          )}
        </>
      )
    }
    return (
      <>
        <WalletUserMenuItem isWrongNetwork={chainId !== ChainId.BSC} onPresentWalletModal={onClickWalletMenu} />
        <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
          {t('Recent Transactions')}
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem>
        <UserMenuDivider />
        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {t('Disconnect')}
            <ActualLogoutIcon />
          </Flex>
        </UserMenuItem>
      </>
    )
  }

  if (isWrongNetwork) {
    return <UIKitUserMenu variant="danger">{({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}</UIKitUserMenu>
  }

  return (
    <>
      {width <= 1200 || account ? (
        <UIKitUserMenu
          style={{
            height: width >= 1200 ? '44px' : '40px',
            padding: '0 10px',
            border: `1px solid ${hoverWallet ? theme.colors.alienPurple : theme.colors.inputBorder}`,
            borderRadius: '10px',
          }}
          onMouseEnter={() => setHoverWallet(true)}
          onMouseLeave={() => setHoverWallet(false)}
          account={account}
          text={userMenuText}
          hideLabel={width <= 852}
          variant={userMenuVariable}
          avatarSrc={
            connector?.id?.toLowerCase() === 'metamask' ? '/images/wallets/metamask.png'
            : connector?.id?.toLowerCase() === 'walletconnect' ? '/images/wallets/wallet-connect.png'
            : connector?.id?.toLowerCase() === 'bsc' ? '/images/wallets/bsc.png'
            : connector?.id?.toLowerCase() === 'trustwallet' ? '/images/wallets/trust-wallet.png'
            : ''}
        >
          {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
        </UIKitUserMenu>
      ) : (
        <ConnectWalletButton scale="sm" style={{ height: 'auto', fontSize: '14px' }}>
          <Box
            style={{ fontFamily: 'Syncopate, sans-serif', letterSpacing: '-0.03em' }}
            display={['none', , , 'block']}
          >
            <Trans>Connect Wallet</Trans>
          </Box>
          <Box
            style={{ fontFamily: 'Syncopate, sans-serif', letterSpacing: '-0.03em' }}
            display={['block', , , 'none']}
          >
            <Trans>Connect</Trans>
          </Box>
        </ConnectWalletButton>
      )}
    </>
  )
}

export default UserMenu
