import { LinkStatus } from '@pancakeswap/uikit/src/widgets/Menu/types'

export const useMenuItemsStatus = (): Record<string, string | (() => LinkStatus)> => {

    return {
      '/competition': 'soon',
      '/ifo': 'soon',
      '/pottery': 'soon',
      '/voting': 'votingStatus',
    }
}
