import styled from 'styled-components'
import { Text, Spinner } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { AutoColumn, ColumnCenter } from 'components/Layout/Column'
import { Trade, Currency, TradeType } from '@pancakeswap/sdk'
import { StableTrade } from '../StableSwap/hooks/useStableTradeExactIn'

const Wrapper = styled.div`
  width: 100%;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 24px 0;
`

function ConfirmationPendingContent({ trade }: { trade: Trade<Currency, Currency, TradeType> | StableTrade }) {
  const { t } = useTranslation()

  // text to show while loading
  const pendingText = t('Swapping %amountA% %symbolA% for %amountB% %symbolB%', {
    amountA: trade?.inputAmount?.toSignificant(6) ?? '',
    symbolA: trade?.inputAmount?.currency?.symbol ?? '',
    amountB: trade?.outputAmount?.toSignificant(6) ?? '',
    symbolB: trade?.outputAmount?.currency?.symbol ?? '',
  })

  return (
    <Wrapper>
      {/* <ConfirmedIcon>
        <Spinner />
      </ConfirmedIcon> */}
      <AutoColumn gap="12px" justify="center" style={{height: '100%'}}>
        <AutoColumn gap="12px" justify="center" style={{width: '80%', height: '100%'}}>
        <Text fontSize={24} color="text" textAlign="center" mt='80px' mb='80px'>
          {t('Confirm this transaction in your wallet')}
        </Text>
        <Text color='textSubtle' fontWeight={500} fontSize={14} textAlign="center">
          {pendingText}
        </Text>
        </AutoColumn>
        
      </AutoColumn>
    </Wrapper>
  )
}

export default ConfirmationPendingContent
// TODO: add animation for pending confirmation