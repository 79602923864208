import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { Box, Flex, LogoutIcon, Menu as UikitMenu, UserMenuItem, UserMenu as UIKitUserMenu } from '@pancakeswap/uikit'
import { useTranslation, languageList } from '@pancakeswap/localization'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import useTheme from 'hooks/useTheme'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { usePhishingBannerManager } from 'state/user/hooks'
import UserMenu from './UserMenu'
import { useMenuItems } from './hooks/useMenuItems'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'
import { SettingsMode } from './GlobalSettings/types'
import MenuIcon from '@pancakeswap/uikit/src/widgets/Menu/components/UserMenu/MenuIcon'
import { StyledUserMenu } from '@pancakeswap/uikit/src/widgets/Menu/components/UserMenu'
import styled from 'styled-components'
import { useWindowDimensions } from 'state/wallet/hooks'

const PriceNavUserMenu = styled(StyledUserMenu)`
  cursor: auto;

  &:hover {
    opacity: 1;
  }
`

const LabelTextFixed = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;
  display: block;
  margin-left: 8px;
  margin-right: 4px;
`

const Menu = (props) => {
  const { isDark, setTheme } = useTheme()
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const [showPhishingWarningBanner] = usePhishingBannerManager()
  const albPrice = cakePriceUsd ? parseFloat(cakePriceUsd.toFixed(4)) : 0
  const [albPriceChange24h, setAlbPriceChange24h] = useState(0)
  const { width } = useWindowDimensions()

  const albIconSrc = '/images/tokens/0x1dd2d631c92b1aCdFCDd51A0F7145A50130050C4.png'

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetch('https://api.dexscreener.com/latest/dex/tokens/0x1dd2d631c92b1aCdFCDd51A0F7145A50130050C4')
        .then((response) => response.json())
        .then((data) => {
          setAlbPriceChange24h(parseFloat(data.pairs[0].priceChange.h24))
        })
        .catch((error) => {
          console.error('Errore durante la chiamata API:', error)
        })
    }, 2000) // 2000ms delay

    return () => clearTimeout(timer) // Cleanup if component is unmounted
  }, [])

  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
        }}
        rightSide={
          <>
            {/* <GlobalSettings mode={SettingsMode.GLOBAL} /> */}
            {/* <Flex alignItems="center" height="100%" mr="8px">
              <PriceNavUserMenu>
                <MenuIcon avatarSrc={albIconSrc} variant="default" />
                <LabelTextFixed>{`$${albPrice}`}</LabelTextFixed>
              </PriceNavUserMenu>
            </Flex> */}
            {width >= 1200 && <NetworkSwitcher />}
            <UserMenu />
          </>
        }
        banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyCakeLabel={t('Buy CAKE')}
        {...props}
      />
    </>
  )
}

export default Menu
