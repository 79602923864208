import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n\n  ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  display: flex;\n  align-items: center;\n  text-align: center;\n  border-radius: 16px;\n  background: transparent;\n  text-transform: uppercase;\n  font-family: Syncopate, sans-serif;\n  color: ",
        ";\n  border-radius: ",
        ";\n  box-shadow: ",
        ";\n  font-size: 14px;\n  height: 40px;\n  letter-spacing: -0.54px;\n  font-weight: 700;\n  overflow: hidden;\n  opacity: ",
        ";\n\n  @media (min-width: 1200px) {\n    padding: 24px 0px;\n    height: 60px;\n    font-size: 18px;\n  }\n\n  ",
        '\n\n  &:hover {\n    transition: all 0.3s ease;\n    color: #fff;\n    svg {\n      transition: all 0.3s ease;\n      fill: #fff;\n    }\n  }\n\n  /* Styles for "Coming Soon" badge */\n  .coming-soon-badge {\n    position: absolute;\n    text-align: center;\n    top: 50%;\n    transform: translateY(-50%) scale(0.95); // Initial scale down\n    right: -5px;\n    left: -5px;\n    top: 70px;\n    background-color: ',
        ";\n    border-radius: 12px;\n    padding: 4px 8px;\n    font-size: 12px;\n    color: ",
        ";\n    font-weight: 700;\n    z-index: 10;\n    opacity: 0; // Hide initially\n    pointer-events: none; // Ensure it doesn't interfere with other interactions\n    transition: transform 0.3s ease, opacity 0.3s ease; // Apply a smooth transition for transform and opacity\n  }\n\n  &:hover .coming-soon-badge {\n    opacity: 1; // Show on hover\n    transform: translateY(-50%) scale(1); // Scale back up on hover\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var StyledMenuItemContainer = styled.div.withConfig({
    componentId: "sc-b887cf7c-0"
})(_templateObject(), function(param) {
    var $isActive = param.$isActive, $variant = param.$variant, theme = param.theme;
    return $isActive && $variant === "subMenu" && '\n      &:after{\n        content: "";\n        position: absolute;\n        bottom: 0;\n        height: 2px;\n        width: 100%;\n        background-color: '.concat(theme.colors.primary, ";\n        border-radius: 2px 2px 0 0;\n      }\n    ");
});
// const StyledMenuItem = styled.a<StyledMenuItemProps>`
//   position: relative;
//   display: flex;
//   align-items: center;
//   border-radius: 16px;
//   color: ${({ theme, $isActive }) => ($isActive ? theme.colors.secondary : theme.colors.textSubtle)};
//   background: ${({ theme, $isActive }) => ($isActive ? theme.colors.tertiary : "")};
//   font-size: 16px;
//   font-weight: 500;
//   opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
//   ${({ $statusColor, theme }) =>
//     $statusColor &&
//     `
//     &:after {
//       content: "";
//       border-radius: 100%;
//       background: ${theme.colors[$statusColor]};
//       height: 8px;
//       width: 8px;
//       margin-left: 12px;
//     }
//   `}
//   ${({ $variant }) =>
//     $variant === "default"
//       ? `
//     padding: 0 16px;
//     height: 48px;
//   `
//       : `
//     padding: 4px 4px 0px 4px;
//     height: 42px;
//   `}
//   &:hover {
//     background: ${({ theme }) => theme.colors.tertiary};
//     ${({ $variant }) => $variant === "default" && "border-radius: 16px;"};
//   }
// `;
// export default StyledMenuItem;
var StyledMenuItem = styled.a.withConfig({
    componentId: "sc-b887cf7c-1"
})(_templateObject1(), function(param) {
    var theme = param.theme, $isActive = param.$isActive;
    return $isActive ? theme.colors.text : theme.colors.textSubtle;
}, function(param) {
    var $variant = param.$variant;
    return $variant === "right" ? "0px 10px 10px 0px" : $variant === "left" ? "10px 0px 0px 10px" : "0px";
}, function(param) {
    var $isActive = param.$isActive, theme = param.theme;
    return $isActive ? "0 -1px 0 0 ".concat(theme.colors.alienPurple, " inset") : "";
}, function(param) {
    var $isDisabled = param.$isDisabled;
    return $isDisabled ? 0.5 : 1;
}, function(param) {
    var $statusColor = param.$statusColor, theme = param.theme;
    return $statusColor && '\n    &:after {\n      content: "";\n      border-radius: 100%;\n      background: '.concat(theme.colors[$statusColor], ";\n      height: 8px;\n      width: 8px;\n      margin-left: 12px;\n    }\n  ");
}, function(param) {
    var theme = param.theme;
    return theme.colors.white;
}, function(param) {
    var theme = param.theme;
    return theme.colors.primary;
});
export default StyledMenuItem;
