import { SetStateAction, useCallback, useEffect, useState, Dispatch, useMemo, useContext } from 'react'
import styled, { useTheme } from 'styled-components'
import { Currency, CurrencyAmount, Token } from '@pancakeswap/sdk'
import {
  Button,
  Text,
  ArrowDownIcon,
  Box,
  IconButton,
  ArrowUpDownIcon,
  Skeleton,
  Flex,
  Message,
  MessageText,
} from '@pancakeswap/uikit'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/UnsupportedCurrencyFooter'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import AccessRisk from 'views/Swap/components/AccessRisk'

import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { CommonBasesType } from 'components/SearchModal/types'
import { AutoRow, RowBetween } from 'components/Layout/Row'
import { AutoColumn } from 'components/Layout/Column'

import { useCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from 'hooks/useApproveCallback'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'

import { Field } from 'state/swap/actions'
import { useDerivedSwapInfo, useSwapState } from 'state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance } from 'state/user/hooks'

import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { currencyId } from 'utils/currencyId'

import CurrencyInputHeader from './CurrencyInputHeader'
import SwapCommitButton from './SwapCommitButton'
import useWarningImport from '../hooks/useWarningImport'
import useRefreshBlockNumberID from '../hooks/useRefreshBlockNumber'
import AddressInputPanel from './AddressInputPanel'
import AdvancedSwapDetailsDropdown from './AdvancedSwapDetailsDropdown'
import TradePrice from './TradePrice'
import { ArrowWrapper, Wrapper } from './styleds'
import { useDerivedStableSwapInfo } from '../StableSwap/hooks/useDerivedStableSwapInfo'
import useApproveCallbackFromStableTrade from '../StableSwap/hooks/useApproveCallbackFromStableTrade'
import InfoTooltip from '@pancakeswap/uikit/src/components/Timeline/InfoTooltip'
import { PURE_STABLECOIN_ADDRESS, StableConfigContext } from '../StableSwap/hooks/useStableConfig'
import StableSwapCommitButton from '../StableSwap/components/StableSwapCommitButton'
import { StyledRowBorders } from './AdvancedSwapDetails'

const Label = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textSubtle};
`

const SwitchIconButton = styled(IconButton)`
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.colors.inputBorder};
  background: transparent;
  height: 44px !important;
  width: 44px !important;
  border-radius: 50% !important;
  .icon-up-down {
    display: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.inputBorder};
    .icon-down {
      display: none;
      fill: white;
    }
    .icon-up-down {
      display: block;
      fill: white;
    }
  }
`

interface SwapForm {
  isChartExpanded: boolean
  isChartDisplayed: boolean
  setIsChartDisplayed: Dispatch<SetStateAction<boolean>>
}

export default function SwapForm({ setIsChartDisplayed, isChartDisplayed, isAccessTokenSupported }) {
  const { t } = useTranslation()
  const { refreshBlockNumber, isLoading } = useRefreshBlockNumberID()
  const warningSwapHandler = useWarningImport()

  const { account, chainId } = useActiveWeb3React()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // state for stable swap eligible pairs
  const [isStableSwap, setStableSwap] = useState(false)

  // swap state & price data
  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const stableConfig = useContext(StableConfigContext)

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency ?? undefined,
      [Field.OUTPUT]: outputCurrency ?? undefined,
    }),
    [inputCurrency, outputCurrency],
  )

  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    inputError: swapInputError,
  } = useDerivedSwapInfo(independentField, typedValue, inputCurrency, outputCurrency, recipient)

  const {
    v2Trade: stableSwapTrade,
    currencyBalances: stableSwapCurrencyBalances,
    parsedAmount: stableSwapParsedAmount,
    inputError: stableSwapSwapInputError,
  } = useDerivedStableSwapInfo(independentField, typedValue, inputCurrency, outputCurrency)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade

  const parsedAmounts = isStableSwap
    ? {
        [Field.INPUT]: independentField === Field.INPUT ? stableSwapParsedAmount : stableSwapTrade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? stableSwapParsedAmount : stableSwapTrade?.outputAmount,
      }
    : showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()

  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]:
      showWrap && !isStableSwap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage, chainId)
  const [stableSwapApproval, stableSwapApproveCallback] = useApproveCallbackFromStableTrade({
    trade: stableSwapTrade,
    allowedSlippage,
    swapAddress: stableConfig.stableSwapConfig?.stableSwapAddress,
  })

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING || stableSwapApproval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, stableSwapApproval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  const handleInputSelect = useCallback(
    (currencyInput) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, currencyInput)

      if (isStableSwap) warningSwapHandler(currencyInput)

      replaceBrowserHistory('inputCurrency', currencyId(currencyInput))
    },
    [onCurrencySelection, warningSwapHandler],
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    (currencyOutput) => {
      onCurrencySelection(Field.OUTPUT, currencyOutput)
      if (isStableSwap) warningSwapHandler(currencyOutput)

      replaceBrowserHistory('outputCurrency', currencyId(currencyOutput))
    },

    [onCurrencySelection, warningSwapHandler],
  )

  useEffect(() => {
    const bothSelectedStable = [
      currencies?.OUTPUT instanceof Token ? currencies?.OUTPUT.address : '',
      currencies?.INPUT instanceof Token ? currencies?.INPUT.address : '',
    ].every((address) => PURE_STABLECOIN_ADDRESS.includes(address))
    setStableSwap(bothSelectedStable)
  }, [currencies?.OUTPUT, currencies?.INPUT])

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const hasAmount = Boolean(parsedAmount)
  const onRefreshPrice = useCallback(() => {
    if (hasAmount) {
      refreshBlockNumber()
    }
  }, [hasAmount, refreshBlockNumber])

  const stableSwapHasAmount = Boolean(stableSwapParsedAmount)
  const stableSwapOnRefreshPrice = useCallback(() => {
    if (stableSwapHasAmount) {
      refreshBlockNumber()
    }
  }, [stableSwapHasAmount, refreshBlockNumber])

  const theme = useTheme()

  return (
    <>
      <>
        {isStableSwap ? (
          <CurrencyInputHeader
            title={t('StableSwap')}
            subtitle={t('Trade tokens in an instant')}
            setIsChartDisplayed={setIsChartDisplayed}
            isChartDisplayed={isChartDisplayed}
            hasAmount={stableSwapHasAmount}
            onRefreshPrice={stableSwapOnRefreshPrice}
          />
        ) : (
          <CurrencyInputHeader
            title={t('Swap')}
            subtitle={t('Trade tokens in an instant')}
            setIsChartDisplayed={setIsChartDisplayed}
            isChartDisplayed={isChartDisplayed}
            hasAmount={hasAmount}
            onRefreshPrice={onRefreshPrice}
          />
        )}
        <Wrapper id="swap-page" style={{ minHeight: '412px' }}>
          <AutoColumn gap="sm">
            {/* <SwapTabButtons swapTypeState={currentSwap} setSwapType={changeSwap} /> */}
            <CurrencyInputPanel
              label={
                independentField === Field.OUTPUT && ((!showWrap && trade) || (isStableSwap && stableSwapTrade))
                  ? t('From (estimated)')
                  : t('From')
              }
              value={formattedAmounts[Field.INPUT]}
              // showMaxButton={!atMaxAmountInput}
              showMaxButton
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
              showCommonBases
              commonBasesType={CommonBasesType.SWAP_LIMITORDER}
            />

            <AutoColumn justify="space-between">
              <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                <SwitchIconButton
                  variant="primary"
                  scale="sm"
                  style={{ width: '44px', height: '44px' }}
                  onClick={() => {
                    setApprovalSubmitted(false) // reset 2 step UI for approvals
                    onSwitchTokens()
                  }}
                >
                  <ArrowDownIcon
                    className="icon-down"
                    color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? 'primary' : 'text'}
                    width="25px"
                  />
                  <ArrowUpDownIcon
                    className="icon-up-down"
                    color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? 'primary' : 'text'}
                    width="25px"
                  />
                </SwitchIconButton>
                {!isStableSwap && recipient === null && !showWrap && isExpertMode ? (
                  <Button variant="text" id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                    {t('+ Add a send (optional)')}
                  </Button>
                ) : null}
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={
                independentField === Field.INPUT && ((!showWrap && trade) || (isStableSwap && stableSwapTrade))
                  ? t('To (estimated)')
                  : t('To')
              }
              showMaxButton
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
              showCommonBases
              commonBasesType={CommonBasesType.SWAP_LIMITORDER}
            />

            <Box style={{ display: isAccessTokenSupported ? 'block' : 'none' }}>
              <AccessRisk inputCurrency={currencies[Field.INPUT]} outputCurrency={currencies[Field.OUTPUT]} />
            </Box>

            {!isStableSwap && isExpertMode && recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDownIcon width="16px" />
                  </ArrowWrapper>
                  <Button variant="text" id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                    {t('- Remove send')}
                  </Button>
                </AutoRow>
                <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
              </>
            ) : null}

            {!showWrap && (
              <AutoColumn gap="0px">
                <>
                  {Boolean(trade) && (
                    <StyledRowBorders showBorderTop>
                      <Label>{t('Price')}</Label>
                      {isLoading ? (
                        <Skeleton width="100%" ml="8px" height="24px" />
                      ) : (
                        <TradePrice
                          price={trade?.executionPrice}
                          showInverted={showInverted}
                          setShowInverted={setShowInverted}
                        />
                      )}
                    </StyledRowBorders>
                  )}
                </>
                <StyledRowBorders align="center" mb="16px" showBorderBottom showBorderTop>
                  <Label>{t('Slippage Tolerance')}</Label>
                  <Text style={{ width: 'auto', fontSize: '14px', fontWeight: 500 }} color="text">
                    {allowedSlippage / 100}%
                  </Text>
                </StyledRowBorders>
              </AutoColumn>
            )}
          </AutoColumn>
          {!isStableSwap || typedValue ? null : (
            <AutoColumn>
              <Message variant="danger" mb="14px">
                <MessageText mb='14px'>
                  <Text mt='14px' mb='10px' fontSize="14px">You are using StableSwap</Text>
                  {t('StableSwap provides better rates and lower fees for pairs with highly correlated prices')}
                </MessageText>
              </Message>
            </AutoColumn>
          )}
          <Box mt="0.25rem">
            {isStableSwap ? (
              <StableSwapCommitButton
                account={account}
                approval={stableSwapApproval}
                approveCallback={stableSwapApproveCallback}
                approvalSubmitted={approvalSubmitted}
                currencies={currencies}
                isExpertMode={isExpertMode}
                trade={stableSwapTrade}
                swapInputError={stableSwapSwapInputError}
                currencyBalances={stableSwapCurrencyBalances}
                allowedSlippage={allowedSlippage}
                onUserInput={onUserInput}
              />
            ) : (
              <SwapCommitButton
                swapIsUnsupported={swapIsUnsupported}
                account={account}
                showWrap={showWrap}
                wrapInputError={wrapInputError}
                onWrap={onWrap}
                wrapType={wrapType}
                parsedIndepentFieldAmount={parsedAmounts[independentField]}
                approval={approval}
                approveCallback={approveCallback}
                approvalSubmitted={approvalSubmitted}
                currencies={currencies}
                isExpertMode={isExpertMode}
                trade={trade}
                swapInputError={swapInputError}
                currencyBalances={currencyBalances}
                recipient={recipient}
                allowedSlippage={allowedSlippage}
                onUserInput={onUserInput}
              />
            )}
          </Box>
        </Wrapper>
        {!swapIsUnsupported ? (
          trade && <AdvancedSwapDetailsDropdown trade={trade} />
        ) : (
          <UnsupportedCurrencyFooter currencies={[currencies.INPUT, currencies.OUTPUT]} />
        )}
      </>
    </>
  )
}
