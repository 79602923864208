import { rinkeby, mainnet, goerli } from 'wagmi/chains'
import { Chain } from 'wagmi'

export const avalandche: Chain = {
  id: 43114,
  name: 'Avalanche C-Chain',
  network: 'avalanche',
  rpcUrls: {
    default: 'https://rpc.ankr.com/avalanche',
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://snowtrace.io/',
    },
  },
}

export const avalandcheFuji: Chain = {
  id: 43113,
  name: 'Avalanche Fuji',
  network: 'avalanche-fuji',
  rpcUrls: {
    default: 'https://rpc.ankr.com/avalanche_fuji',
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://testnet.snowtrace.io/',
    },
  },
  testnet: true,
}

export const fantomOpera: Chain = {
  id: 250,
  name: 'Fantom Opera',
  network: 'fantom',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: 'https://rpc.ftm.tools',
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://ftmscan.com',
    },
  },
}

export const fantomTestnet: Chain = {
  id: 4002,
  name: 'Fantom Testnet',
  network: 'fantom-testnet',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: 'https://rpc.testnet.fantom.network',
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://testnet.ftmscan.com',
    },
  },
  testnet: true,
}

const bscExplorer = { name: 'BscScan', url: 'https://bscscan.com' }
const baseTestExplorer = { name: 'BaseScan Goerli', url: 'https://goerli.basescan.org/' }
const baseExplorer = { name: 'BaseScan', url: 'https://basescan.org/' }

export const baseMain: Chain = {
  id: 56,
  name: 'BNB Smart Chain',
  network: 'bsc',
  rpcUrls: {
    public: 'https://bsc-dataseed1.binance.org',
    default: 'https://bsc-dataseed1.binance.org',
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  multicall: {
    address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    blockCreated: 15921452,
  },
}

export const bscTest: Chain = {
  id: 84531,
  name: 'Base Testnet',
  network: 'bsc-testnet',
  rpcUrls: {
    public: 'https://goerli.base.org',
    default: 'https://goerli.base.org',
  },
  blockExplorers: {
    default: baseTestExplorer,
    etherscan: baseTestExplorer,
  },
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  },
  multicall: {
    address: '0x7920fbf3b05d49eDF62C03E43E2982beF2FEA013',
    blockCreated: 0,
  },
  testnet: true,
}

export const bsc: Chain = {
  id: 8453,
  name: 'Base',
  network: 'base',
  rpcUrls: {
    public: 'https://base.drpc.org',
    default: 'https://base.drpc.org',
  },
  blockExplorers: {
    default: baseExplorer,
    etherscan: baseExplorer,
  },
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  },
  multicall: {
    address: '0x7920fbf3b05d49eDF62C03E43E2982beF2FEA013',
    blockCreated: 2353902,
  },

}

// export const baseTest: Chain = {
//   id: 97,
//   name: 'BNB Smart Chain Testnet',
//   network: 'bsc-testnet',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'Binance Chain Native Token',
//     symbol: 'tBNB',
//   },
//   rpcUrls: {
//     public: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
//     default: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
//   },
//   blockExplorers: {
//     default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
//   },
//   multicall: {
//     address: '0xcA11bde05977b3631167028862bE2a173976CA11',
//     blockCreated: 17422483,
//   },
//   testnet: true,
// }

export { rinkeby, mainnet, goerli }
