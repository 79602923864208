// Banner.js
import React from 'react'
import styled from 'styled-components'
import bannerConfig from './bannerConfig.json'
import { Button, CloseIcon, Flex } from '@pancakeswap/uikit'
import CupRight from './CupRight'
import CupLeft from './CupLeft'
import { useWindowDimensions } from 'state/wallet/hooks'

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  padding: 0 20px;
  background: linear-gradient(0deg, #eee8fd -6.08%, #8049fe 21.72%, #030014 85.23%);
  color: #fff;
  align-items: center;
`

const MainContainer = styled.main`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h1`
  leading-trim: both;
  margin-left: 20px;
  text-edge: cap;
  font-family: Syncopate;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
  letter-spacing: -0.54px;
  text-transform: uppercase;
  margin-right: 15px;
  @media (max-width: 968px) {
    display: none;
  }
`

const Subtitle = styled.p`
  margin: 0;
  font-size: 18px;
  margin-right: 60px;
  @media (max-width: 968px) {
    font-size: 14px;
    margin-right: 20px;
  }
`

const StyledButton = styled(Button)`
  display: flex;
  width: 150px;
  height: 44px;
  text-transform: none;
  padding: 15px 20px 16px 20px;
  justify-content: center;
  box-shadow: 0px 0px 12px 0px rgba(191, 151, 255, 0.44) inset;
  border: 1px solid #7f6bad;
  background: linear-gradient(180deg, rgba(60, 8, 126, 0.46) 0%, rgba(60, 8, 126, 0.94) 100%);
  border-radius: 10px;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  cursor: pointer;
  transition: box-shadow 0.3s;
  position: relative;
  @media (max-width: 968px) {
    font-size: 14px;
    padding: 11px 15px 12px 15px;
  }
`

const Banner = () => {
  const [showBanner, setShowBanner] = React.useState(true)
  const { width } = useWindowDimensions()
  return (
    showBanner && (
      <BannerContainer>
        <MainContainer>
          <div />
          <Flex alignItems="center" as="a" href={bannerConfig.link}>
            {width >= 968 && <CupLeft mb='-4px' />}
            <Title>{bannerConfig.title}</Title>
            <Subtitle>{bannerConfig.subtitle}</Subtitle>
            <StyledButton className="predict-button">{bannerConfig.buttonText}</StyledButton>
            {width >= 968 && <CupRight mb='-4px' />}
          </Flex>
          <div>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setShowBanner(false)} />
          </div>
        </MainContainer>
      </BannerContainer>
    )
  )
}

export default Banner
