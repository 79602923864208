import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["RINKEBY"] = 4] = "RINKEBY";
    ChainId[ChainId["GOERLI"] = 5] = "GOERLI";
    ChainId[ChainId["BSC"] = 8453] = "BSC";
    ChainId[ChainId["BSC_TESTNET"] = 84531] = "BSC_TESTNET";
    ChainId[ChainId["BASE_TESTNET"] = 97] = "BASE_TESTNET";
    ChainId[ChainId["BASE_MAINNET"] = 56] = "BASE_MAINNET";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS = "0x2d5dd5fa7B8a1BFBDbB0916B42280208Ee6DE51e" // BASE MAINNET
;
var FACTORY_ADDRESS_ETH = "0x1097053Fd2ea711dad45caCcc45EfF7548fCB362";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.ETHEREUM, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.RINKEBY, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.GOERLI, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.BSC, FACTORY_ADDRESS), _define_property(_obj, ChainId.BSC_TESTNET, "0x0D08B8ad8C6048b78A3614F6fffe4405e35b85D1"), _define_property(_obj, ChainId.BASE_TESTNET, "0x63F0B820C583eE7E9A1345b648CE3a0a7c848280"), _define_property(_obj, ChainId.BASE_MAINNET, "0x0D08B8ad8C6048b78A3614F6fffe4405e35b85D1"), _obj);
export var INIT_CODE_HASH = "0xd5923f7c1334f581d889028f2997a3a2233cca1fc920b728f236fe7a7255466e";
var INIT_CODE_HASH_ETH = "0x57224589c67f3f30a6b0d7a1b54cf3153ab84563bc609ef41dfb34f8b2974d2d";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.ETHEREUM, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.RINKEBY, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.GOERLI, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH), _define_property(_obj1, ChainId.BSC_TESTNET, "0xd5923f7c1334f581d889028f2997a3a2233cca1fc920b728f236fe7a7255466e"), _define_property(_obj1, ChainId.BASE_TESTNET, "0xd5923f7c1334f581d889028f2997a3a2233cca1fc920b728f236fe7a7255466e"), _define_property(_obj1, ChainId.BASE_MAINNET, "0xd5923f7c1334f581d889028f2997a3a2233cca1fc920b728f236fe7a7255466e"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9900);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
var _obj3;
export var WETH9 = (_obj3 = {}, _define_property(_obj3, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.RINKEBY, new Token(ChainId.RINKEBY, "0xc778417E063141139Fce010982780140Aa0cD5Ab", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.GOERLI, new Token(ChainId.GOERLI, "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.BSC_TESTNET, new Token(ChainId.BSC_TESTNET, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.BASE_TESTNET, new Token(ChainId.BASE_TESTNET, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.BSC, new Token(ChainId.BSC, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.BASE_MAINNET, new Token(ChainId.BASE_MAINNET, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped Ether", "https://weth.io")), _obj3);
var _obj4;
export var WBNB = (_obj4 = {}, _define_property(_obj4, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0x418D75f65a02b3D53B2418FB8E1fe493759c7605", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj4, ChainId.BSC, new Token(ChainId.BSC, "0x4200000000000000000000000000000000000006", 18, "WETH", "WETHB", "https://www.ethereum.org")), _define_property(_obj4, ChainId.BSC_TESTNET, new Token(ChainId.BSC_TESTNET, "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _obj4);
var _obj5;
export var ALB = (_obj5 = {}, _define_property(_obj5, ChainId.BASE_TESTNET, new Token(ChainId.BASE_TESTNET, "0x3BD2586a7F433951806995757Ed266Ce67892224", 18, "ALB", "Alienbase", "https://app.alienbase.xyz/")), _define_property(_obj5, ChainId.BASE_MAINNET, new Token(ChainId.BASE_MAINNET, "0x3BD2586a7F433951806995757Ed266Ce67892224", 18, "ALB", "Alienbase", "https://app.alienbase.xyz/")), _obj5);
var _obj6;
export var WNATIVE = (_obj6 = {}, _define_property(_obj6, ChainId.ETHEREUM, WETH9[ChainId.ETHEREUM]), _define_property(_obj6, ChainId.RINKEBY, WETH9[ChainId.RINKEBY]), _define_property(_obj6, ChainId.GOERLI, WETH9[ChainId.GOERLI]), _define_property(_obj6, ChainId.BSC, WETH9[ChainId.BSC]), _define_property(_obj6, ChainId.BSC_TESTNET, WETH9[ChainId.BSC_TESTNET]), _define_property(_obj6, ChainId.BASE_TESTNET, WETH9[ChainId.BASE_TESTNET]), _define_property(_obj6, ChainId.BASE_MAINNET, WETH9[ChainId.BASE_MAINNET]), _obj6);
var _obj7;
export var NATIVE = (_obj7 = {}, _define_property(_obj7, ChainId.ETHEREUM, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj7, ChainId.RINKEBY, {
    name: "Rinkeby Ether",
    symbol: "RIN",
    decimals: 18
}), _define_property(_obj7, ChainId.GOERLI, {
    name: "Goerli Ether",
    symbol: "GOR",
    decimals: 18
}), _define_property(_obj7, ChainId.BSC, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj7, ChainId.BSC_TESTNET, {
    name: "Wrapped Ether",
    symbol: "WETH",
    decimals: 18
}), _define_property(_obj7, ChainId.BASE_TESTNET, {
    name: "Wrapped Ether",
    symbol: "WETH",
    decimals: 18
}), _define_property(_obj7, ChainId.BASE_MAINNET, {
    name: "Wrapped Ether",
    symbol: "WETH",
    decimals: 18
}), _obj7);
