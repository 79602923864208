import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`

  * {
    font-family: 'DM Sans', sans-serif;
  }
  body {
    background-color: #030014;
    position: relative;
    overflow-x: hidden;

    &::before {
      content: '';
      position: absolute;
      top: -400px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 700px;
      background: rgba(159, 169, 185, 0.08); 
      border-radius: 614px;
      filter: blur(450px); 
      z-index: -1;
    }

    img {
      height: auto;
      max-width: 100%;
    }

    .syncopate {
      font-family: 'Syncopate', sans-serif !important;
    }
    .dmsans {
      font-family: 'DM Sans', sans-serif;
    }
`

export default GlobalStyle
