import _define_property from "@swc/helpers/src/_define_property.mjs";
import { scales, variants } from "./types";
var _obj;
export var scaleVariants = (_obj = {}, _define_property(_obj, scales.MD, {
    height: "60px",
    padding: "0 24px"
}), _define_property(_obj, scales.SM, {
    height: "32px",
    padding: "0 16px"
}), _define_property(_obj, scales.XS, {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px"
}), _obj);
var _obj1;
export var styleVariants = (_obj1 = {}, _define_property(_obj1, variants.PRIMARY, {
    background: "var(--colors-buttonGradientBackground)",
    color: "white",
    // height: "60px",
    padding: "16px 18px",
    fontWeight: 700,
    fontSize: "18px",
    textTransform: "uppercase",
    border: "1px solid #7563A0",
    fontFamily: "Syncopate, sans-serif",
    borderRadius: "10px",
    boxShadow: "0px 0px 12px 0px rgba(191, 151, 255, 0.44) inset",
    ":disabled": {
        fontFamily: "Syncopate, sans-serif",
        fontWeight: 700,
        fontSize: "18px",
        textTransform: "uppercase",
        background: "var(--colors-primary_disabled)",
        boxShadow: "0px",
        color: "var(--colors-textSubtle)"
    }
}), _define_property(_obj1, variants.SECONDARY, {
    backgroundColor: "transparent",
    border: "1px solid var(--colors-inputBorder)",
    borderColor: "inputBorder",
    boxShadow: "none",
    color: "text",
    borderRadius: "10px",
    ":disabled": {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        ":hover": {
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            border: "none"
        }
    },
    ":hover": {
        backgroundColor: "transparent",
        borderColor: "alienPurple"
    }
}), _define_property(_obj1, variants.TERTIARY, {
    backgroundColor: "rgba(255, 255, 255, 0.10)",
    boxShadow: "none",
    color: "#EEF0F4",
    ":disabled": {
        backgroundColor: "#100D1F",
        boxShadow: "none",
        color: "var(--colors-textSubtle)",
        "svg": {
            fill: "var(--colors-textSubtle)"
        }
    }
}), _define_property(_obj1, variants.SUBTLE, {
    backgroundColor: "textSubtle",
    color: "backgroundAlt"
}), _define_property(_obj1, variants.DANGER, {
    backgroundColor: "var(--colors-backgroundDisabled)",
    color: "#D88303"
}), _define_property(_obj1, variants.SUCCESS, {
    backgroundColor: "var(--colors-backgroundDisabled)",
    color: "success",
    fontWeight: 700,
    fontSize: "18px",
    letterSpacing: "-0.54px",
    textTransform: "uppercase",
    fontFamily: "Syncopate, sans-serif",
    ":disabled": {
        color: "success",
        fontWeight: 700,
        fontSize: "18px",
        letterSpacing: "-0.54px",
        textTransform: "uppercase",
        fontFamily: "Syncopate, sans-serif"
    }
}), _define_property(_obj1, variants.TEXT, {
    backgroundColor: "transparent",
    color: "textSubtle",
    boxShadow: "none",
    fontSize: "14px"
}), _define_property(_obj1, variants.LIGHT, {
    backgroundColor: "input",
    color: "textSubtle",
    boxShadow: "none"
}), _define_property(_obj1, variants.WHITE, {
    height: "44px",
    background: "linear-gradient(180deg, #EFF1F5 0%, #C9CBCF 100%);",
    color: "#010814",
    padding: "17px 30px 18px 30px",
    boxShadow: "0px 2px 0px 0px #F9FBFF inset, 0px -2px 0px 0px #B1B3B5 inset;",
    borderRadius: "10px",
    fontSize: "14px",
    fontWeight: 700,
    letterSpacing: "-0.42px"
}), _define_property(_obj1, variants.GREENPRED, {
    display: "flex",
    height: "44px",
    color: "#F6F3FF",
    fontFamily: "Syncopate, sans-serif",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "100%",
    letterSpacing: "-0.54px",
    textTransform: "uppercase",
    padding: "10px 30px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "10px",
    border: "1px solid var(--colors-buttonPrimaryGreenBorder)",
    background: "var(--colors-primaryGreen)",
    boxShadow: "0px 0px 12px 0px rgba(102, 255, 182, 0.44) inset"
}), _define_property(_obj1, variants.PINKPRED, {
    display: "flex",
    height: "44px",
    color: "#F6F3FF",
    fontFamily: "Syncopate, sans-serif",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "100%",
    letterSpacing: "-0.54px",
    textTransform: "uppercase",
    padding: "10px 30px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "10px",
    border: "1px solid var(--colors-buttonPrimaryPinkBorder)",
    background: "var(--colors-primaryPink)",
    boxShadow: "0px 0px 12px 0px rgba(255, 102, 212, 0.44) inset"
}), _obj1);
