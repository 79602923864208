import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  border: 0;\n  background: transparent;\n  color: ",
        ";\n  cursor: ",
        ";\n  font-weight: 500;\n  border-left: ",
        ";\n  display: flex;\n  font-size: ",
        ";\n  height: 32px;\n  justify-content: space-between;\n  outline: 0;\n  padding-left: 20px;\n  padding-right: 20px;\n  width: 100%;\n\n  &:is(button) {\n    cursor: ",
        ";\n  }\n\n  &:hover:not(:disabled) {\n    background-color: ",
        ";\n    color: ",
        ";\n    svg {\n      fill: ",
        ";\n    }\n  }\n\n  &:active:not(:disabled) {\n    opacity: 0.85;\n    transform: translateY(1px);\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  border-color: #1B162F;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  border-color: #1B162F;\n  border-style: solid;\n  border-width: 1px 0 0;\n  margin: 10px 0;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border: 1px solid ",
        ";\n  border-radius: 10px;\n  padding-bottom: 10px;\n  padding-top: 10px;\n  pointer-events: auto;\n  margin-bottom: 0;\n  width: 240px;\n  visibility: visible;\n  z-index: 1001;\n\n  ",
        "\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-size: 12px;\n  font-weight: 400;\n  position: relative;\n  top: -2px;\n  margin-left: 5px;\n  display: inline-flex;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { Text } from "../Text";
var getTextColor = function(param) {
    var $isActive = param.$isActive, disabled = param.disabled, theme = param.theme;
    if (disabled) return theme.colors.textDisabled;
    if ($isActive) return theme.colors.secondary;
    return theme.colors.textSubtle;
};
export var DropdownMenuItem = styled.button.withConfig({
    componentId: "sc-c0fc8008-0"
})(_templateObject(), function(param) {
    var theme = param.theme, $isDisabled = param.$isDisabled, $isActive = param.$isActive;
    return $isDisabled ? theme.colors.textSubtle : $isActive ? theme.colors.text : theme.colors.textSubtle;
}, function(param) {
    var disabled = param.disabled, $isDisabled = param.$isDisabled;
    return disabled || $isDisabled ? "default" : "pointer";
}, function(param) {
    var _$isActive = param.$isActive, $isActive = _$isActive === void 0 ? false : _$isActive, theme = param.theme;
    return $isActive ? "1px solid ".concat(theme.colors.alienPurple) : "0px";
}, function(param) {
    var $isDisabled = param.$isDisabled;
    return $isDisabled ? "14px" : "18px";
}, function(param) {
    var disabled = param.disabled, $isDisabled = param.$isDisabled;
    return disabled || $isDisabled ? "not-allowed" : "pointer";
}, function(param) {
    var theme = param.theme, $isDisabled = param.$isDisabled;
    return $isDisabled ? "transparent" : theme.colors.hoverUserMenu;
}, function(param) {
    var theme = param.theme, $isDisabled = param.$isDisabled;
    return $isDisabled ? theme.colors.textSubtle : theme.colors.text;
}, function(param) {
    var theme = param.theme, $isDisabled = param.$isDisabled;
    return $isDisabled ? theme.colors.textSubtle : theme.colors.text;
});
export var StyledDropdownMenuItemContainer = styled.div.withConfig({
    componentId: "sc-c0fc8008-1"
})(_templateObject1());
export var DropdownMenuDivider = styled.hr.withConfig({
    componentId: "sc-c0fc8008-2"
})(_templateObject2());
export var StyledDropdownMenu = styled.div.withConfig({
    componentId: "sc-c0fc8008-3"
})(_templateObject3(), function(param) {
    var theme = param.theme;
    return theme.colors.dropdownBg;
}, function(param) {
    var theme = param.theme;
    return theme.colors.hoverUserMenu;
}, function(param) {
    var $isOpen = param.$isOpen;
    return !$isOpen && "\n    pointer-events: none;\n    visibility: hidden;\n  ";
});
export var LinkStatus = styled(Text).withConfig({
    componentId: "sc-c0fc8008-4"
})(_templateObject4());
