import { Box } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const StyledPriceChart = styled(Box)<{
  $isDark: boolean
  $isExpanded: boolean
  $isFullWidthContainer?: boolean
}>`
  border: none;
  border-radius: 0px;
  width: 100%;
  padding-top: 4px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 8px;
    border-top: ${({ theme }) => `1px solid ${theme.colors.inputBorder}`};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.inputBorder}`};
    width: ${({ $isExpanded, $isFullWidthContainer }) => ($isFullWidthContainer || $isExpanded ? '100%' : '80%')};
    height: ${({ $isExpanded }) => ($isExpanded ? 'calc(100vh - 100px)' : '516px')};
  }
`

StyledPriceChart.defaultProps = {
  height: '70%',
}
