import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR } from 'config'
import lpAprs56 from 'config/constants/lpAprs/56.json'

const getLpApr = (chainId: number) => {
  switch (chainId) {
    case 56:
      return lpAprs56
    default:
      return {}
  }
}

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (
  chainId: number,
  poolWeight: BigNumber,
  cakePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  farmAddress: string,
  regularCakePerBlock: number,
): { cakeRewardsApr: number; lpRewardsApr: number } => {
  const yearlyCakeRewardAllocation = poolWeight
    ? poolWeight.times(BLOCKS_PER_YEAR * regularCakePerBlock)
    : new BigNumber(NaN)
  const cakeRewardsApr = yearlyCakeRewardAllocation.times(cakePriceUsd).div(poolLiquidityUsd).times(100)
  let cakeRewardsAprAsNumber = null
  if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
    cakeRewardsAprAsNumber = cakeRewardsApr.toNumber() * 0.85
  }
  const lpRewardsApr = getLpApr(chainId)[farmAddress?.toLowerCase()] ?? 0

  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
}

export const getExtraRewardApr = (
  tokenPriceUsd: string,
  poolLiquidityUsd: BigNumber,
  rewardsPerSec: BigNumber,
  totalSupply: BigNumber,
  totalLpStaked: BigNumber,
  decimals: number
): { cakeRewardsApr: number; lpRewardsApr: number } => {
  // reward per sec * seconds in a year * rewardTokenPrice * lpStaked/lpTotalSupply * total Pool Value in usd

  const yearlyRewardAllocation = new BigNumber(rewardsPerSec?.toString()).div(new BigNumber(10).pow(decimals)).times(new BigNumber(31536000))
  const yearlyRewardAllocationUsd = yearlyRewardAllocation.times(new BigNumber(tokenPriceUsd))

  // const lpStakedRatio = new BigNumber(totalLpStaked?.toString()).div(new BigNumber(totalSupply?.toString()))

  // const yearlyRewardAllocationUsdAdjusted = yearlyRewardAllocationUsd.times(lpStakedRatio)
  const apr = yearlyRewardAllocationUsd.div(poolLiquidityUsd)

  // console.log('To calculate the apr we take the rewards per second', new BigNumber(rewardsPerSec?.toString()).div(new BigNumber(10).pow(decimals)).toString(), 
  // 'multiply it by the seconds in a year', new BigNumber(31536000).toString(),
  // 'multiply that by the token price', new BigNumber(tokenPriceUsd).toString(),
  // // 'multiply that by the ratio between totalLpStaked', new BigNumber(totalLpStaked?.toString()).toString(),
  // // 'and totalSupply', new BigNumber(totalSupply?.toString()).toString(),
  // // 'which results in', lpStakedRatio.toString(),
  // 'and then divide that by the total pool liquidity in usd', poolLiquidityUsd.toString())

  const cakeRewardsApr = apr.isNaN() || !apr.isFinite() ? null : apr.toNumber() * 100

  return { cakeRewardsApr, lpRewardsApr: 0 }
}

export default null
