import _define_property from "@swc/helpers/src/_define_property.mjs";
import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-size: ",
        ';\n  font-weight: 700;\n  text-transform: uppercase;\n  font-family: "Syncopate", sans-serif;\n  letter-spacing: -0.54px;\n\n  ',
        " {\n    font-size: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import Text from "../Text/Text";
import { tags, scales } from "./types";
var _obj;
var style = (_obj = {}, _define_property(_obj, scales.SM, {
    fontSize: "14px",
    fontSizeLg: "14px"
}), _define_property(_obj, scales.MD, {
    fontSize: "18px",
    fontSizeLg: "18px"
}), _define_property(_obj, scales.LG, {
    fontSize: "24px",
    fontSizeLg: "24px"
}), _define_property(_obj, scales.XL, {
    fontSize: "32px",
    fontSizeLg: "40px"
}), _define_property(_obj, scales.XXL, {
    fontSize: "48px",
    fontSizeLg: "64px"
}), _define_property(_obj, scales.XXXL, {
    fontSize: "56px",
    fontSizeLg: "72px"
}), _obj);
var Heading = styled(Text).attrs({
    bold: true
}).withConfig({
    componentId: "sc-d04b1888-0"
})(_templateObject(), function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].fontSize;
}, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.xxl;
}, function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].fontSizeLg;
});
Heading.defaultProps = {
    as: tags.H2
};
export default Heading;
