import { ChainId, Token, WBNB } from '@pancakeswap/sdk'
import { BUSD_TESTNET, CAKE_TESTNET, USDC } from './common'
import { WETH9 } from "@pancakeswap/sdk/src";

export const baseTestnetTokens = {
  weth: WETH9[ChainId.BSC_TESTNET],
  alb: CAKE_TESTNET,
  busd: BUSD_TESTNET,
  usdc: USDC[ChainId.BSC_TESTNET],
  albweth: new Token(
    ChainId.BSC_TESTNET,
    '0xA35cE995A87D482B6C9Dc34E42418AB49A94AA45',
    18,
    'ALB-WETH',
    'ALB-WETH LP',
    'https://www.alienbase.xyz/',
  ),
  chz: new Token(
      ChainId.BSC_TESTNET,
      '0x35a80b2b3700445296041f2b2c5d3c5c365e12e1',
      18,
      'CHZ',
      'Testnet Cheezburger',
      'https://www.cheezburger.lol/',
  ),
  chz11: new Token(
      ChainId.BSC_TESTNET,
      '0xc3A000CFD900c0F30a66b41Cd1776be25179D5D3',
      18,
      'CHZ',
      'Testnet Cheezburger',
      'https://www.cheezburger.lol/',
  ),
}
