import { useState } from 'react'
import styled from 'styled-components'
import { Trade, TradeType, CurrencyAmount, Currency } from '@pancakeswap/sdk'
import { Button, Text, AutoRenewIcon } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { Field } from 'state/swap/actions'
import { AutoColumn } from 'components/Layout/Column'
import QuestionHelper from 'components/QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from 'components/Layout/Row'
import { TOTAL_FEE, LP_HOLDERS_FEE, TREASURY_FEE, BUYBACK_FEE } from 'config/constants/info'
import { basisPointsToPercent, formatExecutionPrice } from 'utils/exchange'
import { StyledBalanceMaxMini, SwapCallbackError } from '../../components/styleds'
import { StyledRowBorders } from 'views/Swap/components/AdvancedSwapDetails'

const SwapModalFooterContainer = styled(AutoColumn)`
  padding: 16px 0px;
  border-radius: ${({ theme }) => theme.radii.default};
  background-color: transparent;
`

export default function StableSwapModalFooter({
  trade,
  slippageAdjustedAmounts,
  isEnoughInputBalance,
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
  allowedSlippage,
}: {
  trade: Trade<Currency, Currency, TradeType>
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance: boolean
  onConfirm: () => void
  swapErrorMessage?: string | undefined
  disabledConfirm: boolean
  allowedSlippage: number
}) {
  const { t } = useTranslation()
  const [showInverted, setShowInverted] = useState<boolean>(false)

  const totalFeePercent = `${(TOTAL_FEE * 100).toFixed(2)}%`
  const lpHoldersFeePercent = `${(LP_HOLDERS_FEE * 100).toFixed(2)}%`
  const treasuryFeePercent = `${(TREASURY_FEE * 100).toFixed(4)}%`
  const buyBackFeePercent = `${(BUYBACK_FEE * 100).toFixed(4)}%`

  return (
    <>
      <SwapModalFooterContainer>
        <StyledRowBorders isSmall showBorderBottom showBorderTop>
          <Text fontSize={14} color="textSubtle">
            {t('Slippage Tolerance')}
          </Text>
          <Text bold color="text" ml="auto" textAlign="end">
            {`${basisPointsToPercent(allowedSlippage).toFixed(1)}%`}
          </Text>
        </StyledRowBorders>
        <StyledRowBorders isSmall align="center" showBorderBottom>
          <Text color="textSubtle" fontSize="14px">
            {t('Price')}
          </Text>
          <Text
            fontSize="14px"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              textAlign: 'right',
              paddingLeft: '10px',
            }}
          >
            {formatExecutionPrice(trade, showInverted)}
            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
              <AutoRenewIcon width="14px" />
            </StyledBalanceMaxMini>
          </Text>
        </StyledRowBorders>

        <StyledRowBorders isSmall showBorderBottom>
          <RowFixed>
            <Text fontSize="14px" color="textSubtle">
              {trade.tradeType === TradeType.EXACT_INPUT ? t('Minimum received') : t('Maximum sold')}
            </Text>
            <QuestionHelper
              text={t(
                'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
              )}
              ml="4px"
            />
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px">
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
            </Text>
            <Text fontSize="14px" marginLeft="4px">
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </Text>
          </RowFixed>
        </StyledRowBorders>

        <StyledRowBorders isSmall showBorderBottom>
          <RowFixed>
            <Text color="textSubtle" fontSize="14px">
              {t('Liquidity Provider Fee')}
            </Text>
            <QuestionHelper
              text={
                <>
                  <Text mb="12px">{t('For each trade 0.01% - 0.10% fee is paid', { amount: totalFeePercent })}</Text>
                  <Text>- {t('50% to LP token holders', { amount: lpHoldersFeePercent })}</Text>
                  <Text>- {t('50% to the Treasury', { amount: treasuryFeePercent })}</Text>
                  {/* <Text>- {t('%amount% towards CAKE buyback and burn', { amount: buyBackFeePercent })}</Text> */}
                </>
              }
              ml="4px"
            />
          </RowFixed>
        </StyledRowBorders>
      </SwapModalFooterContainer>

      <AutoRow>
        <Button
          variant="primary"
          onClick={onConfirm}
          disabled={disabledConfirm}
          mt="12px"
          id="confirm-swap-or-send"
          width="100%"
        >
          {trade.tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance ? t('Swap Anyway') : t('Confirm Swap')}
        </Button>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
