import styled, { useTheme } from 'styled-components'
import {
  ChartIcon,
  Flex,
  Heading,
  HistoryIcon,
  IconButton,
  NotificationDot,
  Text,
  useModal,
  ChartDisableIcon,
} from '@pancakeswap/uikit'
import TransactionsModal from 'components/App/Transactions/TransactionsModal'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { useExpertModeManager } from 'state/user/hooks'
import RefreshIcon from 'components/Svg/RefreshIcon'
import { ReactElement, useCallback } from 'react'
import { SettingsMode } from '../../../components/Menu/GlobalSettings/types'

interface Props {
  title: string | ReactElement
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
}

const CurrencyInputContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  width: 100%;
  @media (min-width: 768px) {
    padding: 40px;
  }
`

const ColoredIconButton = styled(IconButton)<{ isActive?: boolean }>`
  color: ${({ theme }) => theme.colors.textSubtle};
  border: 1px solid ${({ theme, isActive }) => isActive ? theme.colors.alienPurple : theme.colors.inputSecondary};
  height: 24px;
  width: 24px !important;
`

const CurrencyInputHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  setIsChartDisplayed,
  isChartDisplayed,
  hasAmount,
  onRefreshPrice,
}) => {
  const [expertMode] = useExpertModeManager()
  const toggleChartDisplayed = () => {
    setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
  }
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  const handleOnClick = useCallback(() => onRefreshPrice?.(), [onRefreshPrice])
  const theme = useTheme()

  return (
    <CurrencyInputContainer>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
          mr={18}
          style={{ gap: '5px', padding: '15px 0px' }}
        >
          <Flex alignItems="center" style={{ gap: '10px' }} width="100%">
            <Heading
              pt="2px"
              as="h2"
              style={{
                fontFamily: 'Syncopate, sans-serif',
                fontWeight: 700,
                textTransform: 'uppercase',
                fontSize: '18px',
              }}
            >
              {title}
            </Heading>
            {setIsChartDisplayed && (
              <ColoredIconButton
                onClick={toggleChartDisplayed}
                isActive={isChartDisplayed}
                variant="secondary"
                style={{width: '24px'}}
                mr="5px"
              >
                {isChartDisplayed ? (
                  <ChartIcon color="white" />
                ) : (
                  <ChartIcon width="24px" color="textSubtle" />
                )}
              </ColoredIconButton>
            )}
          </Flex>
          <Heading
            as="h3"
            style={{
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans, sans-serif',
              color: theme.colors.textSubtle,
              textTransform: 'none',
              // @ts-ignore
              textWrap: 'nowrap',
            }}
          >
            {subtitle}
          </Heading>
        </Flex>
        <Flex width="100%" justifyContent="end">
          <NotificationDot normalWidth show={expertMode}>
            <GlobalSettings color="textSubtle" mr="0" mode={SettingsMode.SWAP_LIQUIDITY} />
          </NotificationDot>
          <IconButton style={{ border: 'none' }} onClick={onPresentTransactionsModal} variant="text" scale="sm">
            <HistoryIcon color="textSubtle" width="24px" />
          </IconButton>
          <IconButton style={{ border: 'none' }} variant="text" scale="sm" onClick={handleOnClick}>
            <RefreshIcon disabled={!hasAmount} color="textSubtle" width="27px" />
          </IconButton>
        </Flex>
      </Flex>
      {/* <Flex alignContent="flex-start">
        <Text color="textSubtle" fontSize="14px">
          {subtitle}
        </Text>
      </Flex> */}
    </CurrencyInputContainer>
  )
}

export default CurrencyInputHeader
