import {ChainId, Token, WBNB, WETH9} from '@pancakeswap/sdk'
import { BUSD_BSC, CAKE_MAINNET, USDT_BSC } from './common'

export const baseTokens = {
  weth: WETH9[ChainId.BSC],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  eth: new Token(
    ChainId.BSC,
    '0x4200000000000000000000000000000000000006',
    18,
    'ETH',
    'Ether',
    'https://www.ethereum.org/',
  ),
  cake: CAKE_MAINNET,
  usdc: new Token(
    ChainId.BSC,
    '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    6,
    'USDC',
    'USDC (Circle)',
    'https://www.circle.com/',
  ),
  axlusdc: new Token(
      ChainId.BSC,
      '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
      6,
      'axlUSDC',
      'USDC (Axelar)',
      'https://www.axelar.network/',
  ),
  cbeth: new Token(
      ChainId.BSC,
      '0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22',
      18,
      'cbETH',
      'Coinbase Staked ETH',
      'https://www.coinbase.com/',
  ),
  usdplus: new Token(
    ChainId.BSC,
    '0xb79dd08ea68a908a97220c76d19a6aa9cbde4376',
    6,
    'USD+',
    'Overnight USD+',
    'https://www.overnight.fi/',
    ),
  daiplus: new Token(
      ChainId.BSC,
      '0x65a2508c429a6078a7bc2f7df81ab575bd9d9275',
      18,
      'DAI+',
      'Overnight DAI+',
      'https://www.overnight.fi/',
  ),
  dai: new Token(
      ChainId.BSC,
      '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
      18,
      'DAI',
      'Dai',
      'https://www.makerdao.com/',
  ),
  mag: new Token(
      ChainId.BSC,
      '0x2DC1cDa9186a4993bD36dE60D08787c0C382BEAD',
      18,
      'MAG',
      'Magnate Finance',
      'https://www.magnate.finance/',
  ),
  yfx: new Token(
      ChainId.BSC,
      '0x8901cB2e82CC95c01e42206F8d1F417FE53e7Af0',
      18,
      'YFX',
      'YieldFarming Index',
      'https://www.yfxprotocol.xyz/',
  ),
  rebase: new Token(
    ChainId.BSC,
    '0x3421cc14f0e3822cf3b73c3a4bec2a1023b8d9cf',
    9,
    'REBASE',
    'Rebase',
    'https://basescan.org/token/0x3421cc14f0e3822cf3b73c3a4bec2a1023b8d9cf',
  ),
  blackRockInu: new Token(
    ChainId.BSC,
    '0x5c57a7a56369a8cc4542c95a5d130e787e769cc2',
    18,
    'BRRR',
    'BlackRock Inu',
    'https://www.blackrock-inu.com/',
  ),
  veyronPepe: new Token(
    ChainId.BSC,
    '0x025f99977db78317a4eba606998258b502bb256f',
    18,
    'VPPE',
    'Veyron Pepe',
    'https://veyronpepe.com/',
  ),
  wsb: new Token(
      ChainId.BSC,
      '0x649C40689f575AeE2368b52616fe3Af508EF0B98',
      18,
      'WSB',
      'Wall Street Base',
      'https://wallstreetbase.app.com/',
  ),
  chz: new Token(
      ChainId.BSC,
      '0x302ab9ae394D675676Ddb41E294169224824fc9A',
      18,
      'CHZ',
      'Cheezburger',
      'https://cheezburger.lol/',
  ),
}
