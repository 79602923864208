/* eslint-disable react/self-closing-comp */
import { Currency, Pair } from '@pancakeswap/sdk'
import { Button, ChevronDownIcon, Text, useModal, Flex, Box } from '@pancakeswap/uikit'
import styled, { css, useTheme } from 'styled-components'
import { isAddress } from 'utils'
import { useTranslation } from '@pancakeswap/localization'
import { WrappedTokenInfo } from '@pancakeswap/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { StablePair } from 'views/AddLiquidity/AddStableLiquidity/hooks/useStableLPDerivedMintInfo'

import { useBUSDCurrencyAmount } from 'hooks/useBUSDPrice'
import { formatNumber } from 'utils/formatBalance'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { Input as NumericalInput } from './NumericalInput'
import { CopyButton } from '../CopyButton'
import AddToWalletButton from '../AddToWallet/AddToWalletButton'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })<{ zapStyle?: ZapStyle }>`
  padding: 7px;
  height: auto !important;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder} !important;

  ${({ zapStyle, theme }) =>
    zapStyle &&
    css`
      padding: 8px;
      border: 1px solid ${theme.colors.cardBorder};
      border-radius: ${zapStyle === 'zap' ? '0px' : '8px'} 8px 8px ${zapStyle === 'zap' ? '0px' : '8px'};
      height: auto;
    `};
  div {
    div {
      font-family: ${({ selected }) => (selected ? 'Syncopate, sans-serif' : 'DM Sans, sans-serif')};
      font-weight: ${({ selected }) => (selected ? '700' : '400')};
      padding: ${({ selected }) => (!selected ? '0px 5px' : '0px')};
    }
  }
  transition: border-color 200ms ease-in-out;
  &:hover:not(:disabled):not(.button--disabled):not(:active) {
    border: 1px solid ${({ theme }) => theme.colors.alienPurple} !important;};
    opacity: 1 !important;
  }
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 14px 0 20px;
  border-radius: 10px;
`
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 10px;
  height: 60px;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: transparent;
  z-index: 1;
  transition: border-color 200ms ease-in-out;
  &:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.alienPurple};
  }
`
const Container = styled.div<{ zapStyle?: ZapStyle; error?: boolean }>`
  border-radius: 10px;
`

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 10px;
  opacity: 0.6;
  // background-color: ${({ theme }) => theme.colors.backgroundAlt};
`

type ZapStyle = 'noZap' | 'zap'

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | StablePair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  commonBasesType?: string
  zapStyle?: ZapStyle
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean
  showBUSD?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  zapStyle,
  beforeButton,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  commonBasesType,
  disabled,
  error,
  showBUSD,
}: CurrencyInputPanelProps) {
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const amountInDollar = useBUSDCurrencyAmount(
    showBUSD ? currency : undefined,
    Number.isFinite(+value) ? +value : undefined,
  )

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
      commonBasesType={commonBasesType}
    />,
  )

  const theme = useTheme()

  return (
    <Box position="relative" id={id}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex style={{gap: '5px'}}>
          {beforeButton}
          <CurrencySelectButton
            zapStyle={zapStyle}
            className="open-currency-select-button"
            selected={!!currency}
            onClick={() => {
              if (!disableCurrencySelect) {
                onPresentCurrencyModal()
              }
            }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
              ) : currency ? (
                <CurrencyLogo currency={currency} size="30px" style={{ marginRight: '8px' }} />
              ) : null}
              {pair ? (
                <Text id="pair" fontSize="18px" letterSpacing="-0.03em">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </Text>
              ) : (
                <Text id="pair" fontSize="18px" letterSpacing="-0.03em">
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                        currency.symbol.length - 5,
                        currency.symbol.length,
                      )}`
                    : currency?.symbol) || t('Select a currency')}
                </Text>
              )}
              {!disableCurrencySelect && <ChevronDownIcon />}
            </Flex>
          </CurrencySelectButton>
          {token && tokenAddress ? (
            <CopyButton
              ml="4px"
              width="20px"
              buttonColor="textSubtle"
              text={tokenAddress}
              tooltipMessage={t('Token address copied')}
              tooltipTop={-20}
              tooltipRight={40}
              tooltipFontSize={12}
            />
          ) : // <Flex style={{ gap: '4px' }} ml="4px" alignItems="center">
          //   <CopyButton
          //     width="20px"
          //     buttonColor="textSubtle"
          //     text={tokenAddress}
          //     tooltipMessage={t('Token address copied')}
          //     tooltipTop={-20}
          //     tooltipRight={40}
          //     tooltipFontSize={12}
          //   />
          //   <AddToWalletButton
          //     variant="text"
          //     p="0"
          //     height="auto"
          //     width="fit-content"
          //     tokenAddress={tokenAddress}
          //     tokenSymbol={token.symbol}
          //     tokenDecimals={token.decimals}
          //     tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
          //   />
          // </Flex>
          null}
        </Flex>
        {account && (
          <Text
            onClick={!disabled && onMax}
            color="textSubtle"
            fontSize="14px"
            style={{ display: 'inline', cursor: 'pointer', fontWeight: 500 }}
          >
            {!hideBalance && !!currency
              ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
              : ' -'}
          </Text>
        )}
      </Flex>
      <div style={{ height: '12px' }}></div>
      <InputPanel>
        <Container as="label" zapStyle={zapStyle} error={error}>
          <LabelRow>
            <NumericalInput
              error={error}
              disabled={disabled}
              className="token-amount-input"
              value={value}
              onBlur={onInputBlur}
              onUserInput={(val) => {
                onUserInput(val)
              }}
            />
            <Button
              onClick={onMax}
              scale="xs"
              variant="secondary"
              style={{
                textTransform: 'uppercase',
                color: theme.colors.textSubtle,
                border: `1px solid ${theme.colors.inputBorder}`,
                padding: '12px 15px',
                fontSize: '12px',
                fontWeight: 700,
                height: '32px',
                fontFamily: 'Syncopate, sans-serif',
                borderRadius: '99px',
              }}
            >
              {t('Max')}
            </Button>
          </LabelRow>
          {/* <InputRow selected={disableCurrencySelect}>
            {!!currency && showBUSD && Number.isFinite(amountInDollar) && (
              <Text fontSize="12px" color="textSubtle" mr="12px">
                ~{formatNumber(amountInDollar)} USD
              </Text>
            )}
            {account && currency && !disabled && showMaxButton && label !== 'To' && (
              <Button onClick={onMax} scale="xs" variant="secondary" style={{ textTransform: 'uppercase' }}>
                {t('Max')}
              </Button>
            )}
          </InputRow> */}
        </Container>
        {disabled && <Overlay />}
      </InputPanel>
      <div style={{ height: '12px' }}></div>
    </Box>
  )
}
