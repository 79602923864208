import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  MoreIcon,
  AccountIcon,
  AccountFilledIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & {
  hideSubNav?: boolean;
  image?: string;
  isComingSoon?: boolean;  // Added line
  items?: ConfigMenuDropDownItemsType[];
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Swap'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [ 
        // {
        //   label: t('Swap'),
        //   href: '/swap',
        // }, 
        // {
        //   label: t('Limit'),
        //   href: '/limit-orders',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   image: '/images/decorations/3d-coin.png',
        // }, 
        // {
        //   label: t('Portfolio'),
        //   href: '/liquidity',
        // }, 
        // {
        //   label: t('Perpetual'),
        //   href: `https://perp.pancakeswap.finance/${perpLangMap(languageCode)}/futures/BTCUSDT?theme=${perpTheme(
        //     isDark,
        //   )}`,
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Bridge'),
        //   href: 'https://bridge.pancakeswap.finance/',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // }, 
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      // {
      //   label: t('Pools'),
      //   icon: AccountIcon,
      //   fillIcon: AccountFilledIcon,
      //   href: '/liquidity',
      //   showItemsOnMobile: false,
      //   items: [
      //   ].map((item) => addMenuItemSupported(item, chainId)),
      // },
      // {
      //   label: t('Farms'),
      //   href: '/farms',
      //   icon: EarnIcon,
      //   fillIcon: EarnFillIcon,
      //   showItemsOnMobile: false,
      //   supportChainIds: SUPPORT_ONLY_BSC,
      //   items: [/*
      //   {
      //     label: t('Farms'),
      //     href: '/farms',
      //   }, /*
      //   {
      //     label: t('Pools'),
      //     href: '/pools',
      //   }, */
      //   ].map((item) => addMenuItemSupported(item, chainId)),
      // },
      // {
      //   label: t('Area51'),
      //   icon: NftIcon,
      //   fillIcon: NftFillIcon,
      //   href: 'https://area51.alienbase.xyz/',
      //   showItemsOnMobile: false,
      //   // disabled: true,
      //   // isComingSoon: true,
      //   items: [
      //   ].map((item) => addMenuItemSupported(item, chainId)),  
      // }
      {
         label: t('Win'),
         href: '/prediction',
        //  icon: TrophyIcon,
        //  fillIcon: TrophyFillIcon,
         supportChainIds: SUPPORT_ONLY_BSC,
         items: [
        //    {
        //      label: t('Trading Competition'),
        //      href: '/competition',
        //      image: '/images/decorations/tc.png',
        //      hideSubNav: true,
        //  },
           {
             label: t('Prediction'),
             href: '/prediction',
             image: '/images/decorations/prediction.png',
             status: "BETA"
           },
           {
             label: t('Lottery'),
             href: '/lottery',
             image: '/images/decorations/lottery.png',
             status: "BETA"
           },
          //  {
          //    label: t('Pottery (BETA)'),
          //    href: '/pottery',
          //    image: '/images/decorations/lottery.png',
          //  },
         ],
       }, 
       {
        label: t('Earn'),
        href: '/farms',
       //  icon: TrophyIcon,
       //  fillIcon: TrophyFillIcon,
        supportChainIds: SUPPORT_ONLY_BSC,
        items: [
       //    {
       //      label: t('Trading Competition'),
       //      href: '/competition',
       //      image: '/images/decorations/tc.png',
       //      hideSubNav: true,
       //  },
          {
            label: t('Pools'),
            href: '/liquidity',
            image: '/images/decorations/prediction.png',
          },
          {
            label: t('Farms'),
            href: '/farms',
            image: '/images/decorations/lottery.png',
          },
         //  {
         //    label: t('Pottery (BETA)'),
         //    href: '/pottery',
         //    image: '/images/decorations/lottery.png',
         //  },
        ],
      }, 
      //  {
      //    label: t('NFT'),
      //    href: `${nftsBaseUrl}`,
      //    icon: NftIcon,
      //    fillIcon: NftFillIcon,
      //    supportChainIds: SUPPORT_ONLY_BSC,
      //    image: '/images/decorations/nft.png',
      //    items: [
      //      {
      //        label: t('Overview'),
      //        href: `${nftsBaseUrl}`,
      //      },
      //      {
      //        label: t('Collections'),
      //        href: `${nftsBaseUrl}/collections`,
      //      },
      //      {
      //        label: t('Activity'),
      //        href: `${nftsBaseUrl}/activity`,
      //      },
      //    ],
      // },
      ,{
        label: '',
        href: '/info',
        icon: MoreIcon,
        hideSubNav: true,
        items: [
          // {
          //   label: t('Area 51'),
          //   // href: '/info',
          //   disabled: true,
          //   isComingSoon: true,
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
          // {
          //   type: DropdownMenuItemType.DIVIDER,
          // },
          {
            label: t('Analytics'),
            supportChainIds: SUPPORT_ONLY_BSC,
          },
          {
            label: t('Overview'),
            href: '/info',
            supportChainIds: SUPPORT_ONLY_BSC,
          },
          {
            label: t('Tokens'),
            href: '/info/tokens',
            supportChainIds: SUPPORT_ONLY_BSC,
          },
          // {
          //   label: t('Pools'),
          //   href: '/info/pools',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          // },
          // {
          //   type: DropdownMenuItemType.DIVIDER,
          // },
          // {
          //   label: t('Prediction (BETA)'),
          //   href: '/prediction',
          // },
          // {
          //   label: t('Bridge with Squid'),
          //   href: 'https://app.alienbase.xyz/squid',
          // },
          // {
          //   label: t('Bridge with Wormhole'),
          //   href: 'https://app.alienbase.xyz/wormhole',
          // },
          // {
          //   label: t('IFO'),
          //   href: '/ifo',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/ifos/ifo-bunny.png',
          // },
          // {
          //   label: t('Voting'),
          //   href: '/voting',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/voting/voting-bunny.png',
          // },
          {
            type: DropdownMenuItemType.DIVIDER,
          },
          // {
          //   label: t('Leaderboard'),
          //   href: '/teams',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/decorations/leaderboard.png',
          // },
          // {
          //   type: DropdownMenuItemType.DIVIDER,
          // },
          {
            label: t('Blog'),
            href: 'https://medium.com/@alienbase',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Docs'),
            href: 'https://docs.alienbase.xyz/',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
