import { Currency } from '@pancakeswap/sdk'
import useTheme from 'hooks/useTheme'
import BnbWbnbNotice from './BnbWbnbNotice'
import { BNB_ADDRESS } from './constants'
import PriceChart from './PriceChart'
import { getTokenAddress } from './utils'

type PriceChartContainerProps = {
  inputCurrencyId: string
  inputCurrency: Currency
  outputCurrencyId: string
  outputCurrency: Currency
  isChartExpanded: boolean
  setIsChartExpanded: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed: boolean
  currentSwapPrice: {
    [key: string]: number
  }
  isMobile?: boolean
  isFullWidthContainer?: boolean
}

const PriceChartContainer: React.FC<React.PropsWithChildren<PriceChartContainerProps>> = ({
  inputCurrencyId,
  inputCurrency,
  outputCurrency,
  outputCurrencyId,
  isChartExpanded,
  setIsChartExpanded,
  isChartDisplayed,
  isMobile,
  isFullWidthContainer = false,
  currentSwapPrice,
}) => {
  const token0Address = getTokenAddress(inputCurrencyId)
  const token1Address = getTokenAddress(outputCurrencyId)

  const { isDark } = useTheme()

  if (!isChartDisplayed) {
    return null
  }

  const isBnbWbnb = token0Address === BNB_ADDRESS && token1Address === BNB_ADDRESS

  if (isBnbWbnb) {
    return <BnbWbnbNotice isDark={isDark} isChartExpanded={isChartExpanded} />
  }

  return (
    <PriceChart
      token0Address={token0Address}
      token1Address={token1Address}
      inputCurrency={inputCurrency}
      outputCurrency={outputCurrency}
      isDark={isDark}
      isChartExpanded={isChartExpanded}
      setIsChartExpanded={setIsChartExpanded}
      isMobile={isMobile}
      isFullWidthContainer={isFullWidthContainer}
      currentSwapPrice={currentSwapPrice}
      isInDollar
    />
  )
}

export default PriceChartContainer
