import { useMemo } from 'react';
import styled, { ThemeConsumer, keyframes } from 'styled-components';
import { Text, Flex, Box, CloseIcon, IconButton, useMatchBreakpoints, Button, Link } from '@pancakeswap/uikit';
import { useTranslation } from '@pancakeswap/localization';
import { usePhishingBannerManager } from 'state/user/hooks';

const scrollAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-33.3%);
  }
`;

const Container = styled(Flex)`
  overflow: hidden;
  height: 100%;
  padding: 12px;
  align-items: center;
  background: linear-gradient(90deg, rgba(24, 24, 26, 0.5) 40%, rgba(50, 25, 47, 0.5));
  backdrop-filter: blur(24px);
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0px;
    background: linear-gradient(90deg, rgba(24, 24, 26, 0.5) 40%, rgba(50, 25, 47, 0.5));
    backdrop-filter: blur(24px);
  }
`;

const InnerContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ScrollingTextContainer = styled.div`
  display: flex;
  white-space: nowrap;
`;

const ScrollingText = styled.div`
  display: flex;
  animation: ${scrollAnimation} 10s linear infinite; // Adjusted time for a smoother transition

  & > *:not(:last-child) {
    margin-right: 2rem;
  }
`;

const PhishingWarningBanner: React.FC<React.PropsWithChildren> = () => {
  const [, hideBanner] = usePhishingBannerManager();
  
  return (
    <Container className="warning-banner">
      <InnerContainer>
        <ScrollingTextContainer>
          <ScrollingText>
            <Text>·</Text>
            <Text>Get your token listed on Area 51, submissions are live!</Text>
            <Text>·</Text>
            <Link color="white" href="https://docs.alienbase.xyz/area-51">Learn More</Link>

            <Text>·</Text>
            <Text>Get your token listed on Area 51, submissions are live!</Text>
            <Text>·</Text>
            <Link color="white" href="https://docs.alienbase.xyz/area-51">Learn More</Link>

            <Text>·</Text>
            <Text>Get your token listed on Area 51, submissions are live!</Text>
            <Text>·</Text>
            <Link color="white" href="https://docs.alienbase.xyz/area-51">Learn More</Link>

          </ScrollingText>
        </ScrollingTextContainer>
      </InnerContainer>
      {/* <IconButton paddingRight="15px" onClick={hideBanner} variant="text">
        <CloseIcon color="#FFFFFF" />
      </IconButton> */}
    </Container>
  )
}

export default PhishingWarningBanner;
