import { useEffect, useMemo, useState } from 'react'
import { ChainId, Currency } from '@pancakeswap/sdk'
import { Box, Flex, BottomDrawer, useMatchBreakpoints, Heading } from '@pancakeswap/uikit'
import Footer from 'components/Menu/Footer'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { EXCHANGE_DOCS_URLS } from 'config/constants'
import { useDefaultsFromURLSearch } from 'state/limitOrders/hooks'
import { AppBody } from 'components/App'

import styled from 'styled-components'

import { useCurrency } from '../../hooks/Tokens'
import { Field } from '../../state/swap/actions'
import { useSwapState, useSingleTokenSwapInfo } from '../../state/swap/hooks'
import { useExchangeChartManager } from '../../state/user/hooks'
import Page from '../Page'
import PriceChartContainer from './components/Chart/PriceChartContainer'

import SwapForm from './components/SwapForm'
import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import useStableConfig, { StableConfigContext, useStableFarms } from './StableSwap/hooks/useStableConfig'
import { baseTokens } from '@pancakeswap/tokens'

const CHART_SUPPORT_CHAIN_IDS = [ChainId.BSC]
export const ACCESS_TOKEN_SUPPORT_CHAIN_IDS = [ChainId.BSC]

const FarmH1 = styled(Heading)`
  font-size: 32px;
  margin-bottom: 8px;
  color: white;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 48px;
    margin-bottom: 12px;
  }
`
const FarmH2 = styled(Heading)`
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 24px;
    margin-bottom: 24px;
  }
`

const PageHeader = styled(Box)`
  max-width: 500px;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0px 0;
  }
`

export const getChartTokens = (
    inputAddress: string | undefined, 
    outputAddress: string | undefined,
    inputCurrency: Currency | undefined,
    outputCurrency: Currency | undefined,
    ) => {

  // If either of the tokens is ALB, prioritize showing ALB in USD
  if (inputAddress === baseTokens?.cake?.address || outputAddress === baseTokens?.cake?.address) {
    return { 
      token0Address: baseTokens?.cake?.address, 
      token1Address: baseTokens?.usdc?.address ,
      currency0: baseTokens?.cake,
      currency1: baseTokens?.usdc,
    }
  }

  // If none of the tokens is ALB but either is ETH, show ETH in USD
  if (inputAddress === 'ETH' || outputAddress === 'ETH') {
    return { 
      token0Address: '0x4200000000000000000000000000000000000006', 
      token1Address: baseTokens?.usdc?.address,
      currency0: baseTokens?.eth,
      currency1: baseTokens?.usdc, };
  }

  // If neither is ALB or ETH, show the normal chart
  return { 
    token0Address: inputAddress !== baseTokens?.usdc?.address ? inputAddress : outputAddress, 
    token1Address: baseTokens?.usdc?.address,
    currency0: inputAddress !== baseTokens?.usdc?.address ? inputCurrency : outputCurrency,
    currency1: baseTokens?.usdc, 
  };
}

export default function Swap() {
  const { isMobile } = useMatchBreakpoints()
  const [isChartExpanded, setIsChartExpanded] = useState(false)
  const [userChartPreference, setUserChartPreference] = useExchangeChartManager(isMobile)
  const [isChartDisplayed, setIsChartDisplayed] = useState(userChartPreference)

  useDefaultsFromURLSearch()

  useEffect(() => {
    setUserChartPreference(isChartDisplayed)
  }, [isChartDisplayed, setUserChartPreference])

  const { chainId } = useActiveWeb3React()

  // swap state & price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  }

  const { token0Address, token1Address, currency0, currency1 } = getChartTokens(inputCurrencyId, outputCurrencyId, inputCurrency, outputCurrency);

  const singleTokenPrice = useSingleTokenSwapInfo(token0Address, currency0, token1Address, currency1)

  const isChartSupported = useMemo(
    () =>
      // avoid layout shift, by default showing
      !chainId || CHART_SUPPORT_CHAIN_IDS.includes(chainId),
    [chainId],
  )

  const stableFarms = useStableFarms()
  const { onCurrencySelection } = useSwapActionHandlers()
  const stableTokenPair = stableFarms?.length ? stableFarms[0] : null

  useEffect(() => {
    if (stableTokenPair) {
      onCurrencySelection(Field.INPUT, stableTokenPair.token0)
      onCurrencySelection(Field.OUTPUT, stableTokenPair.token1)
    }
  }, [onCurrencySelection, stableTokenPair])

  const { stableSwapConfig, ...stableConfig } = useStableConfig({
    tokenA: stableTokenPair?.token0,
    tokenB: stableTokenPair?.token1,
  })

  const isAccessTokenSupported = useMemo(() => ACCESS_TOKEN_SUPPORT_CHAIN_IDS.includes(chainId), [chainId])
  // chart setting loading
  return (
    <Page removePadding={isChartExpanded} hideFooterOnDesktop={isChartExpanded}>
      <Flex width={1} justifyContent="center" position="relative">
        {!isMobile && isChartSupported && (
          <PriceChartContainer
            inputCurrencyId={token0Address}
            inputCurrency={currency0}
            outputCurrencyId={token1Address}
            outputCurrency={currency1}
            isChartExpanded={isChartExpanded}
            setIsChartExpanded={setIsChartExpanded}
            isChartDisplayed={isChartDisplayed}
            currentSwapPrice={singleTokenPrice}
          />
        )}
        {isChartSupported && (
          <BottomDrawer
            content={
              <PriceChartContainer
                inputCurrencyId={token0Address}
                inputCurrency={currency0}
                outputCurrencyId={token1Address}
                outputCurrency={currency1}
                isChartExpanded={isChartExpanded}
                setIsChartExpanded={setIsChartExpanded}
                isChartDisplayed={isChartDisplayed}
                currentSwapPrice={singleTokenPrice}
                isMobile
              />
            }
            isOpen={isChartDisplayed}
            setIsOpen={setIsChartDisplayed}
          />
        )}
        <Flex flexDirection="column" width={1}>
          <StyledSwapContainer $isChartExpanded={isChartExpanded}>
            <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'} style={{overflow: 'visible'}}>
              <AppBody>
                {/* <SwapTab showStable={isStableSupported}>
                  {(swapTypeState) =>
                    swapTypeState === SwapType.STABLE_SWAP ? (
                      <StableSwapFormContainer
                        setIsChartDisplayed={setIsChartDisplayed}
                        isChartDisplayed={isChartDisplayed}
                        changeSwap={setSwapType}
                        currentSwap={swapTypeState}
                      />
                    ) : (
                      <SwapForm
                        isAccessTokenSupported={isAccessTokenSupported}
                        setIsChartDisplayed={setIsChartDisplayed}
                        isChartDisplayed={isChartDisplayed}
                        changeSwap={setSwapType}
                        currentSwap={swapTypeState}
                      />
                    )
                  }
                </SwapTab> */}
                <StableConfigContext.Provider value={{ stableSwapConfig, ...stableConfig }}>
                  <SwapForm
                    isAccessTokenSupported={isAccessTokenSupported}
                    setIsChartDisplayed={setIsChartDisplayed}
                    isChartDisplayed={isChartDisplayed}
                  />
                </StableConfigContext.Provider>
              </AppBody>
              {/* <Box style={
                { 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  flexDirection: 'column', 
                }
              }>
                <a href='https://app.alienbase.xyz/squid'>
                  <Button 
                    variant='secondary'
                    style={{ whiteSpace: 'nowrap', marginTop: '24px' }}
                  >
                    Bridge to Base Chain
                  </Button>
                </a>
              <img src="/images/PoweredBy_Logos_White.svg" alt='powered-by-squid' style={{ maxWidth:'120px', marginLeft: '8px' }} />
              </Box> */}
            </StyledInputCurrencyWrapper>
          </StyledSwapContainer>
          {isChartExpanded && (
            <Box display={['none', null, null, 'block']} width="100%" height="100%">
              <Footer variant="side" helpUrl={EXCHANGE_DOCS_URLS} />
            </Box>
          )}
        </Flex>
      </Flex>
    </Page>
  )
}
