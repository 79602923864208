import { ResetCSS, ToastListener } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import GlobalCheckClaimStatus from "components/GlobalCheckClaimStatus";
import { NetworkModal } from "components/NetworkModal";
import { FixedSubgraphHealthIndicator } from "components/SubgraphHealthIndicator/FixedSubgraphHealthIndicator";
import { useAccountEventListener } from "hooks/useAccountEventListener";
import useEagerConnect from "hooks/useEagerConnect";
import useEagerConnectMP from "hooks/useEagerConnect.bmp";
import useSentryUser from "hooks/useSentryUser";
import useThemeCookie from "hooks/useThemeCookie";
import useUserAgent from "hooks/useUserAgent";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import Script from "next/script";
import { Fragment } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, useStore } from "state";
import { usePollBlockNumber } from "state/block/hooks";
import { usePollCoreFarmData } from "state/farms/hooks";
import { Blocklist, Updaters } from "..";
import Menu from "../components/Menu";
import Providers from "../Providers";
import GlobalStyle from "../style/Global";
import Banner from "components/Banner";

const EasterEgg = dynamic(() => import("components/EasterEgg"), { ssr: false });

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

function GlobalHooks() {
  usePollBlockNumber();
  useEagerConnect();
  usePollCoreFarmData();
  useUserAgent();
  useAccountEventListener();
  useSentryUser();
  useThemeCookie();
  return null;
}

function MPGlobalHooks() {
  usePollBlockNumber();
  useEagerConnectMP();
  usePollCoreFarmData();
  useUserAgent();
  useAccountEventListener();
  useSentryUser();
  return null;
}

function MyApp(props: AppProps<{ initialReduxState: any }>) {
  const { pageProps, Component } = props;
  const store = useStore(pageProps.initialReduxState);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta
          name="description"
          content="👽 Area51 (Alienbase). The safer place to trade and earn microcap tokens."
        />
        <meta name="theme-color" content="#246afd" />
        <meta
          name="twitter:image"
          content="https://area51.alienbase.xyz/images/hero.png"
        />
        <meta
          name="twitter:description"
          content="👽 Area51 (Alienbase). The safer place to trade and earn microcap tokens."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="👽 Area51 (Alienbase). The safer place to trade and earn microcap tokens."
        />
        <title>Area 51</title>
        {(Component as NextPageWithLayout).mp && (
          // eslint-disable-next-line @next/next/no-sync-scripts
          <script
            src="https://public.bnbstatic.com/static/js/mp-webview-sdk/webview-v1.0.0.min.js"
            id="mp-webview"
          />
        )}
      </Head>
      <Providers store={store}>
        <Blocklist>
          {(Component as NextPageWithLayout).mp ? (
            <MPGlobalHooks />
          ) : (
            <GlobalHooks />
          )}
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <Updaters />
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>

      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG}`}
      />

      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GTAG}');
          
          (function(h,o,t,j,a,r){
           h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
           h._hjSettings={hjid:3607985,hjsv:6};
           a=o.getElementsByTagName('head')[0];
           r=o.createElement('script');r.async=1;
           r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
           a.appendChild(r);
       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        }}
      />
    </>
  );
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<unknown>>;
  /** render component without all layouts */
  pure?: true;
  /** is mini program */
  mp?: boolean;
  /**
   * allow chain per page, empty array bypass chain block modal
   * @default [ChainId.BSC]
   * */
  chains?: number[];
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const ProductionErrorBoundary = Fragment;

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  if (Component.pure) {
    return <Component {...pageProps} />;
  }

  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment;
  const ShowMenu = Component.mp ? Fragment : Menu;

  return (
    <ProductionErrorBoundary>
    <Banner />
      <ShowMenu>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ShowMenu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
      <NetworkModal pageSupportedChains={Component.chains} />
    </ProductionErrorBoundary>
  );
};

export default MyApp;
